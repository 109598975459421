import { createContext, useReducer, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import jwt from 'jwt-decode';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload, loading: false };
    case 'LOGOUT':
      return { ...state, user: null, loading: false };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    loading: true,
  });

  const isTokenExpired = (token) => {
    try {
      const decoded = jwt.decode(token);
      if (!decoded || !decoded.exp) return true;

      const expirationDate = new Date(decoded.exp * 1000);
      const currentDate = new Date();
      return currentDate > expirationDate;
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    const checkAuthStatus = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {
        dispatch({ type: 'LOGIN', payload: user });
      } else {
        dispatch({ type: 'LOGOUT' });
      }

      dispatch({ type: 'SET_LOADING', payload: false });
    };

    checkAuthStatus();

    const intervalId = setInterval(checkAuthStatus, 1000 * 10830);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  if (state.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh' }}>
        <Spinner animation="grow" size="lg" className='loadSpinner'/>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

