import React, { createContext, useContext, useState, useEffect } from 'react';

const MultiSelectContext = createContext();

export const useMultiSelect = () => {
    return useContext(MultiSelectContext);
};

export const MultiSelectProvider = ({ children }) => {
    const [multiSelected, setMultiSelected] = useState([]);

    const addObject = (object) => {
        if (object && object._id) {
            setMultiSelected((prevObjects) => [...prevObjects, object]);
        }
    };

    const removeObject = (object) => {
        setMultiSelected((prevObjects) => prevObjects.filter(obj => obj._id !== object._id));
    };

    const toggleObject = (object) => {
        const objectId = object._id;
      
        if (multiSelected.some((selected) => selected._id === objectId)) {
          setMultiSelected(multiSelected.filter((selected) => selected._id !== objectId));
        } else {
          setMultiSelected([...multiSelected, object]);
        }
      };
      
      

    useEffect(() => {
        console.log('multiSelected updated in context:', multiSelected);
    }, [multiSelected]);

    return (
        <MultiSelectContext.Provider value={{ multiSelected, setMultiSelected, addObject, removeObject, toggleObject }}>
            {children}
        </MultiSelectContext.Provider>
    );
};