import { useContext } from "react";
import { OrgStateContext, OrgDispatchContext } from "../reducers/OrgContext";

export const useOrgContext = () => {
    const state = useContext(OrgStateContext);
    const dispatch = useContext(OrgDispatchContext);
  
    if (state === undefined || dispatch === undefined) {
        throw new Error('useOrgContext must be used within an OrgContextProvider');
    }

    const { organization } = state;

    return { organization, dispatch };
    
};