// src/context/MapContext.js
import React, { createContext, useContext, useState } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoibHVjaWR1c3RlY2giLCJhIjoiY2xnbGRxd2JnMW9jODNmbnk0cWo1MnFjdiJ9.psqGF5pITnc8FaP-ev2S4A';

const MapContext = createContext();

export const MapContextProvider = ({ children }) => {
  const [map, setMap] = useState(null);

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => {
  return useContext(MapContext);
};
