import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';

function usePendoPageTracking() {
  let location = useLocation();
  const { user } = useAuthContext(); 

  useEffect(() => {
    // console.log('Location changed:', location);
    // console.log('Pendo object:', window.pendo);

    if (window.pendo && user) {
      window.pendo.identify({
        visitor: {
          id: user.email,
          name: user.firstName + " " + user.lastName, 
        },
        account: {
          id: user.email || 'Unknown Account' 
        }
      });
    } else if (window.pendo) {
      window.pendo.pageLoad();
    }
  }, [location, user]);
}

export default usePendoPageTracking;
