import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalLoadingContext = createContext();

export const useGlobalLoading = () => {
    return useContext(GlobalLoadingContext);
}

export const GlobalLoadingProvider = ({ children }) => {
    const [globalLoading, setGlobalLoading] = useState(false);

    useEffect(() => {
        // console.log('globalLoading updated in context:', globalLoading);
    }, [globalLoading]);

    return (
        <GlobalLoadingContext.Provider value={{ globalLoading, setGlobalLoading }}>
            {children}
        </GlobalLoadingContext.Provider>
    );
};