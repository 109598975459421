import React, { useState } from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import logoImageHealth from '../images/HCSWHITE.png';

function HealthRegister() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleInputChange = (e, setter) => setter(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
    };

    if (formSubmitted) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
                className='mt-3'
            >
                <Row className='mt-3' style={{ justifyContent: 'center' }}>
                    <Image src={logoImageHealth} height="100px" />
                </Row>
                <Row
                    className='mt-3 justify-content-center'
                    style={{ width: '100%' }}
                >
                    <Col
                        xs={12}
                        md={8} 
                        lg={4} 
                    >
                        <h4 style={{ width: '100%', textAlign: 'center' }}>
                            How it Works
                        </h4>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            Healthcare Safely&trade; uses artificial intelligence and machine learning to build patient safety reports. Healthcare Safely&trade; is specifically designed for healthcare professionals that work in home health, hospice, and other in-home care settings.
                        </h6>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            Input your patient's information and generate a background screening for the patient and others who may potentially be in the home. Analyze crime data within the location to score the safety of the surrounding area. 
                        </h6>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            Once a report has been generated, you can share the link to anyone for the report. Email the link, send it to someone's phone, or retain the link in your electronic medical record system. That report exists for 30 days until it requires an update. 
                        </h6>
                        <h4 
                            style={{ width: '100%', textAlign: 'center' }}
                            className='mt-5'
                        >
                            We Won't Charge Your Credit Card
                        </h4>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            Healthcare Safely&trade; won't charge you for the first 30 days of using this service or until you've generated 5 reports, whichever comes first. Cancel anytime before that at no cost.
                        </h6>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            After 30 days or 10 generated reports, Healthcare Safely&trade; costs $149.99 per month or $1499.99 annually depending on your billing selection until you cancel.
                        </h6>
                    </Col>
                </Row>
                <Row
                    className='mt-3 justify-content-center'
                    style={{ width: '100%' }}
                >
                    <Col
                        xs={12}
                        md={8} 
                        lg={4} 
                    >
                        <Card 
                        style={{ width: '100%' }}
                        className='no-hover-animation mt-3'
                        >
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Label>Select Billing Type</Form.Label>
                                            <Form.Control as='select'>
                                                <option>Select Billing Type</option>
                                                <option>Monthly - $149.99</option>
                                                <option>Annually - $1499.99 - (Save over 15%)</option>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={12} md={6}>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='First Name' 
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Last Name' 
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Form.Label>Credit Card Number</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Credit Card Number' 
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={12} md={6}>
                                            <Form.Label>Expiration</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='MM/YY' 
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Label>CVC</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='CVC' 
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Button 
                                                type='submit'
                                                variant='primary' 
                                                className='mt-3'
                                                style={{ width: '100%' }}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row
                    className='mt-3 justify-content-center'
                    style={{ width: '100%' }}
                >
                    <Col
                        xs={12}
                        md={8} 
                        lg={4} 
                    >
                        <h4 
                            style={{ width: '100%', textAlign: 'center' }}
                            className='mt-5'
                        >
                            Cancel Anytime
                        </h4>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                            Healthcare Safely&trade; doesn't make cancellation difficult. Navigate to our cancellation page and input the required details. We will immediately cancel your account, stop charging you, and remove your access.
                        </h6>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Row className='mt-3' style={{ justifyContent: 'center' }}>
                <Image src={logoImageHealth} height="300px" />
            </Row>
            <Row className='mt-3'>
                <h1>Request an Account for Healthcare Safely&trade;</h1>
            </Row>
            <Row className='mt-3 justify-content-center'
                style={{ width: '100%' }}
            >
                <Col
                    xs={12}
                    md={8} 
                    lg={4} 
                >
                    <Card 
                        style={{ width: '100%' }}
                        className='no-hover-animation mt-3'
                    > 
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='First Name' 
                                        required
                                        value={firstName}
                                        onChange={(e) => handleInputChange(e, setFirstName)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='Last Name' 
                                        required
                                        value={lastName}
                                        onChange={(e) => handleInputChange(e, setLastName)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone Number *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='Phone Number' 
                                        required
                                        value={phoneNumber}
                                        onChange={(e) => handleInputChange(e, setPhoneNumber)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Company Name *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='Company Name'  
                                        required
                                        value={companyName}
                                        onChange={(e) => handleInputChange(e, setCompanyName)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='Email'  
                                        required
                                        value={email}
                                        onChange={(e) => handleInputChange(e, setEmail)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>City *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='City'  
                                        required
                                        value={email}
                                        onChange={(e) => handleInputChange(e, setCity)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>State *</Form.Label>
                                    <Form.Control 
                                        type='text' 
                                        placeholder='State'  
                                        required
                                        value={email}
                                        onChange={(e) => handleInputChange(e, setState)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Button 
                                        type='submit'
                                        variant='primary' 
                                        className='mt-3'
                                        style={{ width: '100%' }}
                                    >
                                        Submit
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default HealthRegister;
