import React, { createContext, useContext, useState, useEffect } from 'react';

const ResultsExpandedContext = createContext();

export const useResultsExpanded = () => {
    return useContext(ResultsExpandedContext);
}

export const ResultsExpandedProvider = ({ children }) => {
    const [resultsExpanded, setResultsExpanded] = useState('');

    useEffect(() => {
        // console.log('resultsExpanded updated in context:', resultsExpanded);
    }, [resultsExpanded]);

    return (
        <ResultsExpandedContext.Provider value={{ resultsExpanded, setResultsExpanded }}>
            {children}
        </ResultsExpandedContext.Provider>
    );
}