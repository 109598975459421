import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalCalendarConext = createContext();

export const useGlobalCalendar = () => {
    return useContext(GlobalCalendarConext);
}

export const GlobalCalendarProvider = ({ children }) => {
    const [ globalHourOfDay, setGlobalHourOfDay ] = useState([]);
    const [ globalDayOfWeek, setGlobalDayOfWeek ] = useState([]);
    const [ globalHourDayofWeek, setGlobalHourDayofWeek ] = useState([]);

    useEffect(() => {
        console.log('global calendar updated in context:', globalHourOfDay, globalDayOfWeek, globalHourDayofWeek);
    }, [globalHourOfDay, globalDayOfWeek, globalHourDayofWeek]);

    return (
        <GlobalCalendarConext.Provider value={{ globalHourOfDay, setGlobalHourOfDay, globalDayOfWeek, setGlobalDayOfWeek, globalHourDayofWeek, setGlobalHourDayofWeek }}>
            {children}
        </GlobalCalendarConext.Provider>
    );
}