import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate} from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import Header from './components/Header';
import APIHeader from './components/APIHeader';
import LoginScreen from './screens/login/LoginScreen';
import RegisterScreen from './screens/login/RegisterScreen';
import VerifyEmailPage from './screens/login/VerifyScreen';
import Password from './screens/login/Password';
import { useAuthContext } from './hooks/useAuthContext';
import NotAllowed from './screens/login/NotAllowed';
import Success from './screens/login/Success';
import PasswordReset from './screens/login/PasswordReset';
import jwtDecode from 'jwt-decode';
import usePendoPageTracking from './hooks/usePendoTracking';
import HealthCareHeader from './components/HealthCareHeader';
import HealthRegister from './healthCareSafely/HealthRegister';
// import LPRMap from './screens/LPR/LPRMap';
// import LPRReport from './screens/LPR/LPRReport';


const MainApp = lazy(() => import('./mainApp/MainApp'));
const APIApp = lazy(() => import('./apiApp/APIApp'));
const HealthApp = React.lazy(() => import('./healthCareSafely/HealthApp'))

function RoutesWrapper() {
  const { user } = useAuthContext();
  const location = useLocation();
  const decoded = user ? jwtDecode(user.token) : null;

  function isLocalhost() {
    return window.location.href.startsWith('http://localhost:3000');
  }
  
  function isHealthcareSafely() {
    return window.location.href.startsWith('https://app.healthcaresafely.com');
  }
  
  function isLucidusTech() {
    return window.location.href.startsWith('https://app.lucidustech.com');
  }

  if ((!decoded || (!decoded.isAPIUser && !decoded.isHealthCareUser)) && location.pathname.startsWith('/api')) {
    return <Navigate to="/" replace />;
  }

  // Redirect API users to /api/* routes if they try to access any other routes
  if (decoded && decoded.isAPIUser && !location.pathname.startsWith('/api')) {
    return <Navigate to="/api" replace />;
  }

  // Redirect healthcare users to /healthcare* routes if they try to access any other routes
  if (decoded && decoded.isHealthCareUser && !location.pathname.startsWith('/healthcare')) {
    return <Navigate to="/healthcare" replace />;
  }

  return (
    <Routes>
      {(isLocalhost() || isLucidusTech() || isHealthcareSafely()) && <Route path='/login' element={<LoginScreen />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/register' element={<RegisterScreen />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/verify' element={<VerifyEmailPage />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/setup' element={<Password />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/register/notallowed' element={<NotAllowed />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/register/success' element={<Success />} />}
      {(isLocalhost() || isLucidusTech()) && <Route path='/reset' element={<PasswordReset />} />}
      {(isLocalhost() || isHealthcareSafely()) && <Route path='/healthcare/register' element={<HealthRegister />} />}
      {user && (decoded.isAPIUser ? <Route path='/api*' element={<APIApp />} /> : decoded.isHealthCareUser ? <Route path='/healthcare*' element={<HealthApp />} /> : <Route path='/*' element={<MainApp />} />)}
    </Routes>
  );
}

function PendoTracker() {
  usePendoPageTracking()
  return null
}

function App() {
  const { loading } = useAuthContext();

  function RenderHeader() {
    const location = useLocation();
    const shouldHideHeader = [
      '/login', 
      '/register', 
      '/verify', 
      '/setup', 
      '/register/notallowed', 
      '/register/success', 
      '/reset',
      '/jail',
      '/jail/graph',
      '/healthcare/register',
      '/healthcare/completereport',
      '/healthcare/sharedreport'
    ].some(path => location.pathname.startsWith(path)) || location.pathname.startsWith('/comms/analyze/');

    
    if (shouldHideHeader) {
      return null; // Do not render the header
    }
  
    if (location.pathname.startsWith('/api')) {
      return <APIHeader />; // Render the API header for /api routes
    }

    if (location.pathname.startsWith('/healthcare')) {
      return <HealthCareHeader />; 
    }
  
    return <Header />; // Render the default header for other routes
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '10vh' }}>
        <Spinner animation="grow" size="lg" />
      </div>
    );
  }

  return (
    <Router className="App">
      <PendoTracker />
      <RenderHeader />
      <main className="py-3">
        <Container>
          <Suspense fallback={<Spinner animation="grow" size='lg' />}>
            <RoutesWrapper />
          </Suspense>
        </Container>
      </main>
    </Router>
  );
}

export default App;