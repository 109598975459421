import React, { createContext, useContext, useState, useEffect } from 'react';

const GraphTypeContext = createContext();

export const useGraphType = () => {
    return useContext(GraphTypeContext);
};

export const GraphTypeProvider = ({ children }) => {
    const [graphType, setGraphType] = useState("grid");

    // useEffect(() => {
    //     console.log('graphType updated in context:', graphType);
    // }, [graphType]);

    return (
        <GraphTypeContext.Provider value={{ graphType, setGraphType }}>
            {children}
        </GraphTypeContext.Provider>
    );
};
