import React, { createContext, useContext, useState, useEffect } from 'react';

const ZoomContext = createContext();

export const useZoom = () => {
    return useContext(ZoomContext);
}

export const ZoomProvider = ({ children }) => {
    const [zoomLevel, setZoomLevel] = useState(3);
    const [centerPoint, setCenterPoint] = useState([-80.4514498972914, 38.9191070962269]);

    // useEffect(() => {
    //     console.log('zoom updated in context:', zoomLevel);
    // }, [zoomLevel]);

    return (
        <ZoomContext.Provider value={{ zoomLevel, setZoomLevel, centerPoint, setCenterPoint }}>
            {children}
        </ZoomContext.Provider>
    );
}