import React, { createContext, useContext, useState, useCallback } from 'react';

const AllDataContext = createContext();

export const useAllData = () => useContext(AllDataContext);

export const AllDataProvider = ({ children }) => {
  const [allData, setAllDataInternal] = useState({
    persons: [],
    emails: [],
    phones: [],
    addresses: [],
    vehicles: [],
    licensePlates: [],
    devices: [],
    scans: [],
    ipAddresses: [],
    signals: [],
    darkDocs: [],
    poi: [],
    ballistics: [],
    timingAdvance: [],
    metaWarrant: [],
    relativesAssociates: [],
    accounts: [],
    sexOffenders: [],
  });

  const [multiSelected, setMultiSelected] = useState([]);

  const toggleObject = useCallback((object) => {
    setMultiSelected((prevSelected) => {
      if (prevSelected.some((item) => item._id === object._id)) {
        return prevSelected.filter((item) => item._id !== object._id);
      } else {
        return [...prevSelected, object];
      }
    });
  }, []);

  const toggleSelectAll = (category, selectAll) => {
    if (selectAll) {
      setMultiSelected(allData[category]);
    } else {
      setMultiSelected([]);
    }
  };

  const setAllData = useCallback((newDataOrFunction) => {
    setAllDataInternal((prevData) => {
      const newData =
        typeof newDataOrFunction === 'function'
          ? newDataOrFunction(prevData)
          : newDataOrFunction;
  
      const updatedData = { ...prevData };
  
      Object.keys(newData).forEach((category) => {
        if (Array.isArray(newData[category])) {
          if (category === 'poi') {
            updatedData.poi = newData.poi;
          } else if (category === 'vehicles' || category === 'scans') {
            // Update both vehicles and scans
            updatedData.scans = Array.isArray(newData.scans) ? [...newData.scans] : [];
            updatedData.vehicles = Array.isArray(newData.vehicles) ? [...newData.vehicles] : [];
        
            // Replace scan IDs with scan objects in each vehicle's scans array
            updatedData.vehicles = updatedData.vehicles.map(vehicle => {
                if (Array.isArray(vehicle.scans) && typeof vehicle.scans[0] === 'string') {
                    // vehicle.scans is an array of scan IDs
                    const scanObjects = vehicle.scans
                        .map(scanId => updatedData.scans.find(scan => scan._id === scanId))
                        .filter(scan => scan); // Remove undefined scans if any
                    return { ...vehicle, scans: scanObjects, _id: vehicle._id };
                } else {
                    // vehicle.scans is already an array of scan objects
                    return vehicle;
                }
            });
        
            // console.log('updatedData:', updatedData);
        }
        else if (category === 'relativesAssociates') {
            const uniqueItems = {};
            const personsTahoeIds = new Set(
              prevData.persons.flatMap((person) =>
                person.externalIds ? person.externalIds.map((id) => id.tahoeId) : []
              )
            );
  
            [...(prevData[category] || []), ...newData[category]].forEach((item) => {
              if (item && item.externalIds && item.externalIds.length > 0) {
                item.externalIds.forEach((externalId) => {
                  if (externalId.tahoeId && !personsTahoeIds.has(externalId.tahoeId)) {
                    uniqueItems[externalId.tahoeId] = item;
                  }
                });
              }
            });
  
            updatedData[category] = Object.values(uniqueItems);
          } else {
            const uniqueItems = {};
            [...newData[category]].forEach((item) => {
              if (item && item._id) {
                uniqueItems[item._id] = item;
              }
            });
            updatedData[category] = Object.values(uniqueItems);
          }
        } else {
          updatedData[category] = newData[category];
        }
      });
  
      return updatedData;
    });
  }, []);

  return (
    <AllDataContext.Provider value={{ allData, setAllData, multiSelected, toggleObject, toggleSelectAll }}>
      {children}
    </AllDataContext.Provider>
  );
};