import React, { createContext, useContext, useState, useEffect } from 'react';

const HeatmapContext = createContext();

export const useHeatmap = () => {
    return useContext(HeatmapContext);
}

export const HeatmapProvider = ({ children }) => {
    const [ heatmapActivated, setHeatmapActivated ] = useState(false);
    const [ heatmapIntesity, setHeatmapIntesity ] = useState(2.5);
    const [ heatmapRadius, setHeatmapRadius ] = useState(25);

    return (
        <HeatmapContext.Provider 
            value={{ 
                heatmapActivated, 
                setHeatmapActivated,
                heatmapIntesity,
                setHeatmapIntesity,
                heatmapRadius,
                setHeatmapRadius
            }}
        >
            {children}
        </HeatmapContext.Provider>
    );
}