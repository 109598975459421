import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

//Auth Context
import { useAuthContext } from '../../hooks/useAuthContext'
import { useOrgContext } from '../../hooks/useOrgContext'
import { Nav, NavDropdown } from 'react-bootstrap'
import jwtDecode from 'jwt-decode'
import { OrgContext } from '../../reducers/OrgContext'
import { useLogout } from '../../hooks/useLogout'

function OrgHandler() {
    const [ displayOrgs, setDisplayOrgs ] = useState([])
    const [ orgsByID, setOrgsByID ] = useState([])
    const [ selectedOrganization, setSelectedOrganization ] = useState('')
    const { organization, dispatch } = useOrgContext()

    const authContext = useAuthContext()
    const navigate = useNavigate()

    const token = authContext.user ? authContext.user.token : null
    const decoded = jwtDecode(token)
    const orgIDs = decoded.organizations
    const userID = decoded._id

    const { logout } = useLogout()

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    logout();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [logout]);
    
    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/${userID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            const user = userResponse.data;
    
            if (!user.selectedOrganization) {
                const selectOrgResponse = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/api/user/selectOrganization`,
                    {
                        userId: userID,
                        selectedOrganizationId: orgIDs[0],
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                const updatedUser = selectOrgResponse.data.user;
                const updatedOrgResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/organization/${updatedUser.selectedOrganization}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                const updatedOrg = updatedOrgResponse.data;
    
                if (JSON.stringify(organization) !== JSON.stringify(updatedOrg)) {
                    dispatch({ type: 'SET_ORGANIZATION', payload: updatedOrg });
                }
            } else {
                const orgResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/organization/${user.selectedOrganization}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                const org = orgResponse.data;
    
                if (JSON.stringify(organization) !== JSON.stringify(org)) {
                    dispatch({ type: 'SET_ORGANIZATION', payload: org });
                }
            }
        };
    
        if (orgIDs.length > 0) {
            fetchData();
        }
    }, [token, orgIDs, userID, organization, dispatch]);
    

    // console.log("TEST ORG", organization)

    useEffect(() => {
        const userOrg = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/${userID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            const user = response.data
            const currentOrg = user.selectedOrganization
            setSelectedOrganization(currentOrg)
        }
        userOrg()
    }, [ token ])

    useEffect(() => {
        const fetchAllUserOrgs = async () => {
            try {
                const orgsPromises = orgIDs.map(orgID => axios.get(`${process.env.REACT_APP_BASE_URL}/api/organization/${orgID}`, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
                ));
                const orgsResponses = await Promise.all(orgsPromises);
                const fetchedOrgs = orgsResponses.map(response => response.data);
                setDisplayOrgs(fetchedOrgs);
            } catch (error) {
                console.error("Failed to fetch organizations:", error);
            }
        }
    
        fetchAllUserOrgs();
    }, [ token ]);

    const handleSelection = useCallback(async (orgId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/selectOrganization`, {
                userId: userID,
                selectedOrganizationId: orgId
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
            );
            const updatedUser = response.data;
            // console.log("updated User", updatedUser.user.selectedOrganization);
            setSelectedOrganization(updatedUser.user.selectedOrganization);
    
            const organizationResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/organization/${updatedUser.user.selectedOrganization}`, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
            );
            // console.log("Updated User Organization", organizationResponse.data);
    
            if (JSON.stringify(organization) !== JSON.stringify(organizationResponse.data)) {
                dispatch({ type: 'SET_ORGANIZATION', payload: organizationResponse.data });
            }
        } catch (error) {
            console.error("Failed to select organization:", error);
        }
    }, [userID, organization, selectedOrganization]);

    if (orgIDs.length > 2) {
        return (
            <div>
                <NavDropdown title="Organizations" id="organizations" className='ms-3 mt-4'>
                    {displayOrgs.length === 0 ? 
                        <NavDropdown.Item>Loading...</NavDropdown.Item> :
                        displayOrgs.map((org, index) => 
                            <NavDropdown.Item key={index} onClick={() => handleSelection(org._id)}>
                                {org.agencyName}
                                {org._id === selectedOrganization && 
                                    <i className="fa-solid fa-circle-check ms-3"></i>
                                }
                            </NavDropdown.Item>
                        )
                    }
                </NavDropdown>
            </div>
          )
    }

    else {
        return null
    }
}

export default OrgHandler
