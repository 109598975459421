import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

function NotAllowed() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ip = searchParams.get('ip');
  const agent = searchParams.get('agent');

  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center'}}>
        <div style={{ width: '100%'}}>
            <Card
                bg='danger'
                className='ms-5 me-5'
            >
                <Card.Body className='mt-5 mb-5'>
                    <Card.Title>
                        <h1 style={{ textAlign: 'center', color: 'black'}}>
                            <i className="fa-solid fa-triangle-exclamation fa-2xl me-5"></i>
                            You Are Not Authorized To Create An Account
                        </h1>
                    </Card.Title>
                <Card.Text className='ms-5 mt-5'>
                    <h3 style={{ textAlign: 'center', color: 'black'}}><b>Your IP address:</b> {ip}</h3> 
                    <h3 style={{ textAlign: 'center', color: 'black'}}><b>Your User Agent:</b> {agent}</h3>
                </Card.Text>
                <Card.Text className='ms-5 mt-5'>
                    <h1 style={{ textAlign: 'center', color: 'black'}}>
                        Your Information Has Been Logged And Sent To The System Administrator
                    </h1>
                </Card.Text>
                <Card.Text className='ms-5 mt-5'>
                    <h1 style={{ textAlign: 'center', color: 'black'}}>
                        If you believe that this is an error, please contact your system administrator <b>system@lucidustech.com</b>
                    </h1>
                </Card.Text>
                </Card.Body>
            </Card>
      </div>
    </div>
  )
}

export default NotAllowed