import React from 'react'
import { Card } from 'react-bootstrap'

function Success() {
  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center'}}>
    <div style={{ width: '100%'}}>
        <Card
            bg='success'
            className='ms-5 me-5'
        >
            <Card.Body className='mt-5 mb-5'>
                <Card.Title>
                    <h1 style={{ textAlign: 'center', color: 'black'}}>
                        You've Successfully Registered Your Account
                    </h1>
                </Card.Title>
            <Card.Text className='ms-5 mt-5'>
                <h1 style={{ textAlign: 'center', color: 'black'}}>
                    Your Account is Being Reviewed By Our System Administrator and You Will Receive an Email When Your Account Has Been Approved
                </h1>
            </Card.Text>
            <Card.Text className='ms-5 mt-5'>
                <h1 style={{ textAlign: 'center', color: 'black'}}>
                    If you have any questions, please contact the system administrator <b>system@lucidustech.com</b>
                </h1>
            </Card.Text>
            </Card.Body>
        </Card>
  </div>
</div>
  )
}

export default Success
