import React, { createContext, useContext, useState, useEffect } from 'react';

const SelectedObjectContext = createContext();

export const useSelectedObject = () => {
    return useContext(SelectedObjectContext);
};

export const SelectedObjectProvider = ({ children }) => {
    const [selectedObject, setSelectedObject] = useState(null);

    useEffect(() => {
        console.log('selectedObject updated in context:', selectedObject);
    }, [selectedObject]);

    return (
        <SelectedObjectContext.Provider value={{ selectedObject, setSelectedObject }}>
            {children}
        </SelectedObjectContext.Provider>
    );
};