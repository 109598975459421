import { AuthContext } from "../reducers/AuthContext";
import { useContext } from "react";

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context) {
        throw Error('useAuthContext Must Be Used Inside An AuthContextProvider')
    }

    const { user, dispatch, loading } = context;
    return { user, dispatch, loading };
}