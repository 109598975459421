// useCaseContext.js
import { useContext } from "react";
import { CaseStateContext, CaseDispatchContext } from "../reducers/CaseContext";

export const useCaseContext = () => {
    const state = useContext(CaseStateContext);
    const dispatch = useContext(CaseDispatchContext);

    const caseNumber = state ? state.caseNumber : null;

    return { caseNumber, dispatch };
};