import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Row, Col } from 'react-bootstrap';

const VerifyEmailPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    console.log("token", token);

  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/verify?token=${token}`);
        setIsLoading(false)
        console.log(response.data); // Handle the response as needed (success or error)

        // Display a success message or perform any other action
      } catch (error) {
        console.error(error);
        // Display an error message or perform any other action
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div style={{ paddingTop: '64px' }}>
      {isLoading ? (
        <Row>
            <Row>
                <Col sm={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <h1 className='my-3' style={{ textAlign: 'center' }}>Verifying Email...</h1>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center'}}>
                    <Spinner animation='grow' size='lg' className='loadSpinner'/>
                </Col>
            </Row>
        </Row>
      ) : (
        <Row>
            <Row>
                <Col sm={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <h1 className='my-3' style={{ textAlign: 'center' }}>Verified</h1>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center'}}>
                <i class="fa-solid fa-circle-check fa-xl" variant="success"></i>
                </Col>
            </Row>
        </Row>
      )}
    </div>
  );
};

export default VerifyEmailPage;