import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (email, password, trustThisBrowser, twoFactorCode) => {
    setLoading(true);
    setError(null);

    const payload = {
      email,
      password,
      trustThisBrowser,
      twoFactorCode,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, payload);
      console.log(response); // Log the entire response object
    
      const { data } = response;
    
      if (response.status === 200) {
        // Check for the message indicating two-factor authentication initiation
        const requiresTwoFactorAuth = data?.message === "Two-factor authentication initiated. Please check your email for the verification code.";
    
        return { requiresTwoFactorAuth, data };
      } else {
        setError(data?.error || "An error occurred during login.");
        return { requiresTwoFactorAuth: false, data: null };
      }
    } catch (error) {
      // Set the error message based on the response from the server
      setError(error.response?.data?.error || error.message || "An error occurred during login.");
      return { requiresTwoFactorAuth: false, data: null };
    } finally {
      setLoading(false);
    }
  };

  return { login, isLoading, error };
};
