import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import OrgHandler from './OrgHandler'


function AuthenticatedOrgHandler() {
    const authContext = useAuthContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (!authContext.user) {
            navigate('/login');
        }
    }, [authContext.user]);

    if (!authContext.user) {
        return null;
    }

    return <OrgHandler />;
}

export default AuthenticatedOrgHandler
