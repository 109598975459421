import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Navbar, Nav, Container, Row, Col, NavDropdown, Image, Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import jwtDecode from 'jwt-decode';

//Hooks
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useOrgContext } from '../hooks/useOrgContext'; 

import logoImageHealth from '../images/HCSWHITE.png'

function HealthCareHeader() {
    const navigate = useNavigate()
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const { organization } = useOrgContext()

    useEffect(() => {
        if (!user) {
            navigate("/login")
        } 
    }, [user, navigate])

    const token = user?.token
    const decoded = token ? jwtDecode(token) : null

    const initials = user && `${user.firstName[0]}${user.lastName[0]}`

    const logoutHandler = () => {
        logout()
    }

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: '2', width: "100%" }}>
        <Container style={{ width: '100%'}}>
            <LinkContainer to="/healthcare" className="ms-3">
                <Navbar.Brand>
                    <Image src={logoImageHealth} height="70px"/>
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <div style={{ width: '100%'}}>
                    <Nav style={{ display: 'flex', flexDirection: 'row'}}>
                        <LinkContainer to="/healthcare/home">
                            <Nav.Link className='ms-3'><i className="fas fa-home me-2 fa-lg"></i>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/healthcare/reports">
                            <Nav.Link className='ms-3'><i className="fas fa-file-invoice me-2 ms-3 fa-lg"></i>Patient Report Generator</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/healthcare/location">
                            <Nav.Link className='ms-3'><i className="fas fa-map-location-dot me-2 ms-3 fa-lg"></i>Location Safety</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/healthcare/submitted">
                            <Nav.Link className='ms-3'><i className="fas fa-table-list me-2 ms-3 fa-lg"></i>My Patient Reports</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </div>
                <div>
                    {user &&(
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}} className='user-container ms-3'>
                            <Nav>
                                <NavDropdown title={user.email} id="user-profile" className='me-3'>
                                    <LinkContainer to="/healthcare/profile">
                                        <NavDropdown.Item><i className="fas fa-user me-2"></i>Profile</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/login">
                                        <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-right-from-bracket me-2"></i>Logout</NavDropdown.Item>   
                                    </LinkContainer>
                                </NavDropdown>
                            </Nav>
                            <div className="circle-icon">
                                {initials}
                            </div>
                        </div>
                    )}
                </div>  
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default HealthCareHeader
