import { createContext, useReducer, useEffect } from "react";
import { Spinner } from 'react-bootstrap';

export const OrgStateContext = createContext();
export const OrgDispatchContext = createContext();

export const orgReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return { organization: action.payload, loading: false };
    case 'CLEAR_ORGANIZATION':
      return { organization: null, loading: false };
    default:
      return state;
  }
};

export const OrgContextProvider = ({ children }) => {
  const initialOrganization = JSON.parse(localStorage.getItem('organization')) || null;


  const [state, dispatch] = useReducer(orgReducer, {
    organization: initialOrganization,
    loading: initialOrganization === null,
  });


  useEffect(() => {
    const organization = JSON.parse(localStorage.getItem('organization'));

    if (organization) {
      dispatch({ type: 'SET_ORGANIZATION', payload: organization });
    } else {
      dispatch({ type: 'CLEAR_ORGANIZATION' });
    }
  }, []);

  // console.log('OrgContext state:', state);

  if (state.loading) {
    // You can show a loading spinner or any other UI component
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "90vh" }}>
        <Spinner animation="grow" size="lg" className='loadSpinner'></Spinner>
      </div>
    );
  }

  return (
    <OrgDispatchContext.Provider value={dispatch}>
      <OrgStateContext.Provider value={state}>
        {children}
      </OrgStateContext.Provider>
    </OrgDispatchContext.Provider>
  );
};
