import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Form, Button, OverlayTrigger, Tooltip, Modal, ListGroup, Card } from 'react-bootstrap';
import { useSignup } from '../../hooks/useSignup';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AuthContext } from '../../reducers/AuthContext';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { use } from 'cytoscape';

function RegisterScreen({ location, history }) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const emailRef = useRef(null);
    const [phone, setPhone] = useState('')
    const [agencyName, setAgencyName] = useState('')
    const [ori, setORI] = useState('')
    const [password, setPassword] = useState('')
    const { signup, error, isLoading, isRegistered } = useSignup()
    const [suggestions, setSuggestions] = useState(null)
    const [legalModal, setLegalModalShow] = useState(true)
    const [errorModal, setErrorModal] = useState(false);
    const [isVeteran, setIsVeteran] = useState('')
    const [ searchParams ] = useSearchParams()
    const referral = searchParams?.get('referral')
    const navigate = useNavigate()

    const [passwordLength, setPasswordLength] = useState(false)
    const [uppercase, setUppercase] = useState(false)
    const [lowercase, setLowercase] = useState(false)
    const [number, setNumber] = useState(false)
    const [special, setSpecial] = useState(false)
    const [match, setMatch] = useState(false)

    const [isEmailValid, setIsEmailValid] = useState(true);
    const inputStyle = isEmailValid ? {} : { borderColor: 'red', borderWidth: '2px', backgroundColor: '#ffe6e6', boxShadow: '0'}
    const [errorMsg, setErrorMsg] = useState("");
    const ErrorModal = ({errorMessage, errorModal, setErrorModal}) => {
        const [show, setShow] = useState(true);
        const handleClose = () => { 
            setShow(false);
            setErrorModal(false);
        }
        function emailAdmin(){
            window.open('mailto:carter@lucidustech.com,joe@lucidustech.com,nick@lucidustech.com?subject=Registration Error&');
            return;
        }
        return (
            <Modal centered show={show} onHide={handleClose}>
            <Modal.Header >
              <Modal.Title>Registration Error!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                There was a problem registering your account.
                <br></br>
                {errorMessage}
                <br></br><br></br>
                <Button onClick={emailAdmin}>Contact Us</Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }



    const handlePasswordValidation = (passwordInput) => {
        setPasswordLength(passwordInput.length >= 14)
        setUppercase(/[A-Z]/.test(passwordInput));
        setLowercase(/[a-z]/.test(passwordInput));
        setNumber(/\d/.test(passwordInput));
        setSpecial(/[!@#$%^&*]/.test(passwordInput));
    }

    const handleMatch = (e) => {
        const passwordInput = e.target.form.elements.password.value;
        const passwordConfirmationInput = e.target.value;

        setMatch(passwordInput === passwordConfirmationInput);
    }

    useEffect(() => {
        if (error && error.message === 'Not Allowed') {
            navigate(`/register/notallowed?ip=${error.ip}&agent=${error.userAgent}`)
        } else if (error && error.message === "Email Address In Use") {
            console.log(error, error.message);
            setErrorMsg("This email address is already associated with a Lucidus account.")
            setIsEmailValid(false);
            setErrorModal(true);
            emailRef.current.focus();
        }
    }, [error, navigate])

    const fetchSuggestions = async (query) => {
        try {
            if (query.length < 4) {
                setSuggestions(["Continue Typing..."]);
                return;
            }

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/oris/typeahead?query=${query}`
            );
            console.log("Response", response.data);
            const suggestionsData = response.data;
            const transformedSuggestions = suggestionsData.map((item) => ({
                name: item.name,
                ori: item.ori,
                state: item.state,
                _id: item._id,
            }));
            setSuggestions(transformedSuggestions);
        } catch (error) {
            console.log('Error fetching suggestions:', error);
        }
    };

    const handleAgencyNameChange = (selected) => {
        if (selected.length > 0) {
            setAgencyName(selected[0]._id);
            setORI(selected[0].ori);
        } else {
            setAgencyName('')
            setORI('')
        }
    };

    const renderMenuItemChildren = (option, props) => (
        <>
            <span>{option.name}</span>
            <span className="text-muted ms-2">{option.state}</span>
        </>
    );

    const handleSubmit = async (e) => {
        console.log("submitting?...")
        e.preventDefault()
        await signup(firstName, lastName, email, phone, agencyName, ori, isVeteran, password, referral)
    }

    const handleDisagree = () => {
        alert('You must agree to the Registration Agreement to register for an account.')
    }

    useEffect(() => {
        if (isRegistered) {
            navigate('/register/success');
        }
    }, [isRegistered, navigate]);

    return (
        <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* modal to hold errors, pass in errors based on resp from useSignup */}
            {errorModal ? <ErrorModal errorMessage={errorMsg} setErrorModal={setErrorModal}/> : null }
            <Modal
                size='xl'
                show={legalModal}
                centered
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Registration Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '700px', overflow: 'auto' }}>
                    <ListGroup>
                        <ListGroup.Item>
                            <h4>By registering for an account on this platform, you hereby agree to the following terms and conditions:</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h4>1. Eligibility:</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5><i>You are a sworn Law Enforcement Officer based in the United States or a civilian employee of a United States-Based Law Enforcement Agency.</i></h5>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h4 className='mt-4'>2. Violation of Eligibility:</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5><i>Creating an account without meeting the eligibility criteria is a violation of law.</i></h5>
                        </ListGroup.Item>
                        <ListGroup.Item className='mt-4'>
                            <h4>3. Computer Fraud and Abuse Act (CFAA) Compliance:</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5><i>Attempting to create an account without being a sworn Law Enforcement Officer based in the United States or a civilian employee of a United States-Based Law Enforcement Agency is a violation of the Computer Fraud and Abuse Act (CFAA), as outlined in Title 18 U.S. Code § 1030. This federal law criminalizes gaining unauthorized access to protected computers with the intent to defraud or cause damage.</i></h5>
                        </ListGroup.Item>
                        <ListGroup.Item className='mt-4'>
                            <h4>4. Identity Theft and Assumption Deterrence Act (18 U.S.C. § 1028) Compliance:</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5><i>Using someone else's information to create an account is a violation of the Identity Theft and Assumption Deterrence Act (18 U.S.C. § 1028). This federal law prohibits the knowing transfer or use, without lawful authority, of a means of identification of another person with the intent to commit, aid, or abet any unlawful activity that constitutes a violation of federal law or a felony under any applicable state or local law.</i></h5>
                        </ListGroup.Item>
                        <ListGroup.Item className='mt-4'>
                            <h4>5. Consumer Reporting Agency (CRA):</h4>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5><i>Lucidus is not a Consumer Reporting Agency (CRA), and its data and services do not constitute “consumer reports” as defined by the Fair Credit Reporting Act (FCRA). Accordingly, these services and data may not be used as a factor in determining eligibility for credit, insurance, housing, employment, or for any other purpose specified under the FCRA.</i></h5>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => handleDisagree()}>
                        Decline
                    </Button>
                    <Button variant='primary' onClick={() => setLegalModalShow(false)}>
                        I Agree
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                        >
                            <h1 style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className='my-3'>
                                Request Account for Lucidus
                            </h1>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Enter Email'
                                    size='lg'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={inputStyle}
                                    ref={emailRef}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type='tel'
                                    placeholder='Enter Phone'
                                    size='lg'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter First Name'
                                    size='lg'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Last Name'
                                    size='lg'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Agency Name</Form.Label>
                                <AsyncTypeahead
                                    id="agency-typeahead"
                                    placeholder="Agency Name"
                                    labelKey={(option) => `${option.name} (${option.state})`}
                                    size="lg"
                                    isLoading={isLoading}
                                    options={suggestions || []}
                                    onSearch={(query) => fetchSuggestions(query)}
                                    onChange={handleAgencyNameChange}
                                    value={agencyName}
                                    renderMenuItemChildren={renderMenuItemChildren}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Originating Agency Identifier</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Type Agency Name or ORI...'
                                    size='lg'
                                    value={ori}
                                    onChange={(e) => setORI(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>
                                    Are you a US Military Veteran Currently Serving in an Official Law Enforcement Capacity?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Accounts for Veterans are free and always will be.
                                            </Tooltip>
                                        }
                                    >
                                        <i class="fa-solid fa-circle-question ms-2"></i>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control as='select' size='lg' onChange={(e) => setIsVeteran(e.target.value === 'Yes')}>
                                    <option value=''>Select...</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>
                            <Card style={{ width: "95%" }} className='my-3'>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px', paddingTop: '10px' }}>
                                    {passwordLength ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Be at Least 14 Characters</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                                    {uppercase ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Uppercase Letter</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                                    {lowercase ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Lowercase Letter</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                                    {number ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Number</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                                    {special ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Special Character</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px', paddingBottom: '10px' }}>
                                    {match ? (
                                        <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-xmark fa-xl"></i>
                                    )}
                                    <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Match</h6>
                                </div>
                            </Card>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Enter Password'
                                    name='password'
                                    size='lg'
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        handlePasswordValidation(e.target.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Re-Enter Password'
                                    size='lg'
                                    name='passwordConfirmation'
                                    onChange={handleMatch}
                                />
                            </Form.Group>
                            <Button type='submit' variant='primary' size="lg" style={{ width: '100%' }} className='mt-3' disabled={isLoading}>
                                Create Account
                            </Button>
                        </Form>
                        {error &&
                            <Row className='py-3' style={{ display: 'flex', justifyContent: 'center' }}>
                                {error.message}
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default RegisterScreen