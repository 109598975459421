import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalDatesContext = createContext();

export const useGlobalDates = () => {
    return useContext(GlobalDatesContext);
}

export const GlobalDatesProvider = ({ children }) => {
    const [ globalStartDateTime, setGlobalStartDateTime ] = useState('');
    const [ globalEndDateTime, setGlobalEndDateTime ] = useState('');

    useEffect(() => {
        // console.log('globalStartDate updated in context:', globalStartDateTime);
    }, [globalStartDateTime, globalEndDateTime]);

    return (
        <GlobalDatesContext.Provider value={{ globalStartDateTime, setGlobalStartDateTime, globalEndDateTime, setGlobalEndDateTime }}>
            {children}
        </GlobalDatesContext.Provider>
    );
}