import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Form, Row, Button, Card } from 'react-bootstrap'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function PasswordReset() {
    const navigate = useNavigate()
    const [passwordLength, setPasswordLength] = useState(false)
    const [uppercase, setUppercase] = useState(false)
    const [lowercase, setLowercase] = useState(false)
    const [number, setNumber] = useState(false)
    const [special, setSpecial] = useState(false)
    const [match, setMatch] = useState(false)
    const [tokenInvalid, setTokenInvalid] = useState(false);


    const handlePasswordValidation = (e) => {
        const passwordInput = e.target.value;

        setPasswordLength(passwordInput.length >= 14)
        setUppercase(/[A-Z]/.test(passwordInput));
        setLowercase(/[a-z]/.test(passwordInput));
        setNumber(/\d/.test(passwordInput));
        setSpecial(/[!@#$%^&*]/.test(passwordInput));
    }

    const handleMatch = (e) => {
        const passwordInput = e.target.form.elements.password.value;
        const passwordConfirmationInput = e.target.value;

        setMatch(passwordInput === passwordConfirmationInput);
    }

    const handlePasswordSet = async (e) => {
        e.preventDefault()

        const form = e.target.closest('form');
        const passwordInput = form.querySelector('input[name="password"]');
        const passwordConfirmationInput = form.querySelector('input[name="passwordConfirmation"]');
        const password = passwordInput.value;
        const passwordConfirmation = passwordConfirmationInput.value;
        const token = new URLSearchParams(window.location.search).get('token');

        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/reset-password/${token}`, { password });
            console.log(response.data);
            setTokenInvalid(false);
            alert(response.data.message)
            navigate('/login')
        } catch (error) {
            console.log(error.response.data);
            console.log(error)
            // Display err for invalid token.
            if (error.response.status === 400) {
                setTokenInvalid(true);
            }
        }
    }

    const isSubmitDisabled = !passwordLength || !uppercase || !lowercase || !number || !special || !match || tokenInvalid;

    useEffect(() => {
        console.log(tokenInvalid)
        // token invalid or expired.
    }, [tokenInvalid])

    return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
            <Container>
                <Row>
                    <Col>
                        <h1 style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className='my-3'>
                            Setup Password
                        </h1>
                    </Col>
                </Row>
            </Container>
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                <Card style={{ width: "95%" }} className='my-3'>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px', paddingTop: '10px' }}>
                        {passwordLength ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Be at Least 14 Characters</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                        {uppercase ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Uppercase Letter</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                        {lowercase ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Lowercase Letter</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                        {number ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Number</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px' }}>
                        {special ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Contain at Least 1 Special Character</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: '10px', paddingBottom: '10px' }}>
                        {match ? (
                            <i className="fa-solid fa-circle-check fa-xl" style={{ color: "#5cb85c" }}></i>
                        ) : (
                            <i className="fa-solid fa-circle-xmark fa-xl"></i>
                        )}
                        <h6 style={{ paddingLeft: "10px", marginTop: "8px" }}>Password Must Match</h6>
                    </div>
                </Card>
            </Container>
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <Col>
                        {tokenInvalid && (
                            <Alert variant="danger">
                                Your token is invalid or has expired.
                                <br></br>
                                Please request a new password reset.
                            </Alert>
                        )}
                        <Form onSubmit={handlePasswordSet} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: "100%" }}>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label><h5>Enter New Password</h5></Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Password'
                                    size='lg'
                                    name="password"
                                    onChange={handlePasswordValidation}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                                <Form.Label><h5>Reenter Password</h5></Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Password'
                                    size='lg'
                                    name="passwordConfirmation"
                                    required
                                    onChange={handleMatch}
                                />
                            </Form.Group>
                            <Button type='submit' variant='primary' style={{ width: '100%' }} className='mt-3' size='lg' disabled={isSubmitDisabled}>
                                Set New Password
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PasswordReset
