import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { useLocation } from 'react-router-dom'
import { useOrgContext } from '../../hooks/useOrgContext'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useCaseContext } from '../../hooks/useCaseContext'
import { useNavigate } from 'react-router-dom'

function CaseHandler() {

    const { user } = useAuthContext()
    const { organization } = useOrgContext()
    const orgId = organization?._id
    let decoded;
        if (user?.token) {
        try {
            decoded = jwtDecode(user.token);
        } catch (error) {
            console.error('Error decoding token:', error);
            logout();
            navigate('/login');
        }
        }
    const UserId = decoded?._id
    const [ userDetails, setUserDetails ] = useState(null)
    const { logout } = useLogout()
    const [ caseModalShow, setCaseModalShow ] = useState(false)
    const [ typedCaseNumber, setTypedCaseNumber ] = useState('') // For the first input box
    const [ existingCaseNumber, setExistingCaseNumber ] = useState('') // For the second input box
    const location = useLocation()
    const [ caseHistory, setCaseHistory ] = useState([])
    const typeaheadRef = useRef(null)
    const { dispatch, caseNumber } = useCaseContext()
    const navigate = useNavigate()

    // console.log("Context Case Number", caseNumber)

    const handleInvalidTokenError = (error) => {
        if (error.message.includes('Invalid token specified')) {
          logout();
          navigate('/login');
        }
      }

    const getUser = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/${UserId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user?.token}`,
            }
        })
        // console.log('User:', response.data)
        setUserDetails(response.data)
      } catch (error) {
          console.error('Error:', error)
          handleInvalidTokenError(error)
          if (error.response && error.response.data && error.response.data.type === 'EXPIRED_TOKEN') {
              logout()
          }
        }
      }

      useEffect(() => {
        if (!user) {
          navigate('/login');
        } else {
          getUser();
        }
      }, [])

      useEffect(() => {
        if (location.pathname.startsWith('/search') && user && user.RequiresCaseNumber && !caseNumber) {
          setCaseModalShow(true)
          getCaseHistoryForUser()
        }
      }, [location, userDetails, caseNumber])

      const showCaseModal = async () => {
        setCaseModalShow(true)
        await getCaseHistoryForUser()
    }

    const handleClose = () => {
        setCaseModalShow(false)
    }

    const handleCaseNumberChange = (e) => {
        const newCaseNumber = e.target.value
        setTypedCaseNumber(newCaseNumber)
        setExistingCaseNumber('') // clear the existing case number
        localStorage.setItem('caseNumber', newCaseNumber)
    
        // Dispatch the SET_CASE_NUMBER action
        dispatch({ type: 'SET_CASE_NUMBER', payload: newCaseNumber })
    }
    
      const handleExistingCaseNumberChange = (e) => {
        setExistingCaseNumber(e.target.value)
        setTypedCaseNumber('') // clear the case number
        localStorage.setItem('caseNumber', e.target.value)
    
        // Dispatch the SET_CASE_NUMBER action
        dispatch({ type: 'SET_CASE_NUMBER', payload: e.target.value })
      }

    const handleCaseNumberFocus = () => {
        setExistingCaseNumber('') // clear the existing case number
        typeaheadRef.current.clear(); // clear the AsyncTypeahead selection
    }

    const handleExistingCaseNumberFocus = () => {
        setTypedCaseNumber('') // clear the case number
        handleSearch('') // show the first 5 options in the AsyncTypeahead box
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/case-history/create/`, {
                UserId: UserId,
                OrganizationId: orgId,
                caseNumber: typedCaseNumber || existingCaseNumber
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                }
            })
            // console.log('Response:', response.data)
            setCaseModalShow(false)
        } catch (error) {
            console.error('Error:', error)
            handleInvalidTokenError(error)
        }
    }

    const getCaseHistoryForUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/case-history/${UserId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                }
            })
            // console.log('Case History:', response.data)
            setCaseHistory(response.data)
        } catch (error) {
            console.error('Error:', error)
            if (error.response && error.response.data && error.response.data.type === 'EXPIRED_TOKEN') {
                logout()
            }
        }
    }

    const handleSearch = (query) => {
        if (query === '') {
            setCaseHistory(caseHistory.slice(0, 5));
        } else {
            setCaseHistory(caseHistory.filter(caseItem => caseItem.caseNumber.includes(query)));
        }
    }

  return (
    <div>
        <Modal show={caseModalShow} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Enter Case Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Case Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Case Number" value={typedCaseNumber} onChange={handleCaseNumberChange} onFocus={handleCaseNumberFocus} /> 
                    </Form.Group>
                    <Row className='mt-3'>
                    <h3>OR</h3>
                    </Row>
                    <Form.Group>
                        <Form.Label>Select Existing</Form.Label>
                            <AsyncTypeahead
                                ref={typeaheadRef}
                                id="async-typeahead"
                                isLoading={false}
                                labelKey="caseNumber"
                                onSearch={handleSearch}
                                options={caseHistory}
                                placeholder="Click or Type to Select Existing"
                                onChange={(selected) => {
                                    if (selected.length > 0) {
                                    const selectedCaseNumber = selected[0].caseNumber;
                                    setExistingCaseNumber(selectedCaseNumber);
                                    setTypedCaseNumber(''); // clear the case number
                                    localStorage.setItem('caseNumber', selectedCaseNumber);

                                    // Dispatch the SET_CASE_NUMBER action
                                    dispatch({ type: 'SET_CASE_NUMBER', payload: selectedCaseNumber });
                                    }
                                }}
                                onFocus={handleExistingCaseNumberFocus}
                            />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Form.Group>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>Set Case Number</Button>
                </Form.Group>
            </Modal.Footer>
        </Modal>
        {userDetails?.requiresCaseNumber ? 
            <Button onClick={showCaseModal} className='me-3'>
                {localStorage.getItem('caseNumber') ? `Active Case Number: ${localStorage.getItem('caseNumber')}` : 'Enter Case Number'}
            </Button> 
        : null}
    </div>
  )
}

export default CaseHandler