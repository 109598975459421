import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//Bootstrap
import { Navbar, Nav, Container, Row, Col, NavDropdown, Image, Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

//Hooks
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useOrgContext } from '../hooks/useOrgContext'; 

//import logo
import logoImage from '../images/LucidusWhiteOnTransparent.png'

//Components
// import OrgHandler from './orgHandler/OrgHandler';
import AuthenticatedOrgHandler from './orgHandler/AuthenticatedOrgHandler';

//jwt decode
import jwtDecode from 'jwt-decode';

//Custom CSS
import './dropdown.css'
import NotificationsIndicator from './NotificationsIndicator';
import CaseHandler from './caseHanlder/CaseHandler';

function Header() {
    const navigate = useNavigate()
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const { organization } = useOrgContext()

    useEffect(() => {
        if (!user) {
            navigate("/login")
        } 
    }, [user, navigate])

    const token = user?.token
    const decoded = token ? jwtDecode(token) : null

    const initials = user && `${user.firstName[0]}${user.lastName[0]}`

    const logoutHandler = () => {
        logout()
    }

    
  return (
    <header>
        <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: '2', width: "100%" }}>
            <Container style={{ width: '100%'}}>
                <LinkContainer to="/" className="ms-3"> 
                <Navbar.Brand>
                    <Image src={logoImage} height="50px"/>
                </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div style={{ width: '100%'}}>
                        <Nav style={{ display: 'flex', flexDirection: 'row'}}>
                            <LinkContainer to="/home">
                                <Nav.Link className='ms-3'><i className="fas fa-home me-2"></i>Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/search">
                                <Nav.Link className='ms-3'><i className="fas fa-search me-2"></i>Search</Nav.Link>
                            </LinkContainer>
                            {decoded && (decoded.hasJailCallAccess || decoded.hasCommsAccess || decoded.hasBallisticsAccess || decoded.hasBodyWornAccess || decoded.hasInvestigationsAccess) && (
                                <NavDropdown title="Applications" id="applications" className='ms-3'>
                                    {decoded.hasInvestigationsAccess &&
                                    <LinkContainer to ="/investigation">
                                        <NavDropdown.Item><i className="fas fa-magnifying-glass me-2"></i>Investigations</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                    {decoded.hasJailCallAccess &&
                                    <LinkContainer to ="/jail">
                                        <NavDropdown.Item><i className="fas fa-handcuffs me-2"></i>Inmate Intelligence</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                    {decoded.hasCommsAccess &&
                                    <LinkContainer to ="/comms/home">
                                        <NavDropdown.Item><i className="fas fa-phone me-2"></i>Communications Analyzer</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                    {decoded.hasBallisticsAccess &&
                                    <LinkContainer to ="/ballistics">
                                        <NavDropdown.Item><i className="fas fa-person-rifle me-2"></i>Ballistics Analyzer</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                    {decoded.hasBodyWornAccess &&
                                    <LinkContainer to ="/bwc">
                                        <NavDropdown.Item><i className="fas fa-video me-2"></i>BodyWorn Application</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                </NavDropdown>
                            )}
                            { decoded && (decoded.isGlobalAdmin || decoded.isLocalAdmin) && (
                                <NavDropdown title="Admin" id="adminmenu" className='ms-3 mt-4'>
                                    { decoded.isGlobalAdmin && 
                                    <LinkContainer to="/admin/global/users">
                                        <NavDropdown.Item><i className="fas fa-globe me-2"></i>Global</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                    { decoded.isLocalAdmin && 
                                    <LinkContainer to="/admin/users">
                                        <NavDropdown.Item><i className="fas fa-people-group me-2"></i>Organizational</NavDropdown.Item>
                                    </LinkContainer>
                                    }
                                </NavDropdown>
                            )}
                            <AuthenticatedOrgHandler />
                        </Nav>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}} className='me-5'>
                        <CaseHandler />
                    </div>
                    <div>
                        {user &&(
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}} className='user-container ms-3'>
                            <NotificationsIndicator />
                            <Nav>
                                <NavDropdown title={user.email} id="user-profile" className='me-3'>
                                    <LinkContainer to="/profile">
                                        <NavDropdown.Item><i className="fas fa-gear me-2"></i>Settings</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/notifications">
                                        <NavDropdown.Item>
                                            <i className="fas fa-bell me-2"></i>
                                            Notifications
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/messages">
                                        <NavDropdown.Item><i className="fas fa-comments me-2"></i>Messages</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/casehistory">
                                        <NavDropdown.Item><i className="fa-solid fa-briefcase me-2"></i>Case History</NavDropdown.Item>
                                    </LinkContainer>
                                    {user.isIdentityAuditer && (
                                        <LinkContainer to="/optout">
                                            <NavDropdown.Item><i className="fa-solid fa-user-slash me-2"></i>Opt Out Form</NavDropdown.Item>
                                        </LinkContainer>
                                    )}
                                    <LinkContainer to="/login">
                                        <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-right-from-bracket me-2"></i>Logout</NavDropdown.Item>   
                                    </LinkContainer>
                                </NavDropdown>
                            </Nav>
                            <div className="circle-icon">
                                {initials}
                            </div>
                        </div>
                        )}
                    </div>           
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
  )
}

export default Header
