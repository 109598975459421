import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

// Context for managing all data
const AllDataContext = createContext();

// Hook to access the AllDataContext
export const useAllData = () => {
  return useContext(AllDataContext);
};

// Context provider component
export const AllDataProvider = ({ children }) => {
  const [allData, setAllDataInternal] = useState({
    persons: [],
    emails: [],
    phones: [],
    addresses: [],
    vehicles: [],
    licensePlates: [],
    devices: [],
    scans: [],
    ipAddresses: [],
    signals: [],
    darkDocs: [],
    poi: [],
    ballistics: [],
    timingAdvance: [],
    metaWarrant: [],
    relativesAssociates: [],
    accounts: [],
    sexOffenders: [],
  });

  // State to manage selected items for multi-select functionality
  const [multiSelected, setMultiSelected] = useState([]);

  // Toggle the selection state of a given object
  const toggleObject = useCallback((object) => {
    setMultiSelected((prevSelected) => {
      if (prevSelected.some((item) => item._id === object._id)) {
        return prevSelected.filter((item) => item._id !== object._id);
      } else {
        return [...prevSelected, object];
      }
    });
  }, []);

  // Function to select or deselect all items in a given category
  const toggleSelectAll = (category, selectAll) => {
    if (selectAll) {
      setMultiSelected(allData[category]);
    } else {
      setMultiSelected([]);
    }
  };

  const setAllData = useCallback((newDataOrFunction) => {
    setAllDataInternal((prevData) => {
      const newData =
        typeof newDataOrFunction === 'function'
          ? newDataOrFunction(prevData)
          : newDataOrFunction;
  
      // Create a new updatedData object based on prevData
      const updatedData = { ...prevData };
  
      // Iterate over each category in the newData
      Object.keys(newData).forEach((category) => {
        if (Array.isArray(newData[category])) {
          if (category === 'poi') {
            updatedData.poi = newData.poi;
          } else if (category === 'vehicles' || category === 'scans') {
            // Update both vehicles and scans
            updatedData.scans = [...newData.scans];
            updatedData.vehicles = [...newData.vehicles];
            console.log('updatedData:', updatedData);
          } else if (category === 'relativesAssociates') {
            const uniqueItems = {};
            const personsTahoeIds = new Set(
              prevData.persons.flatMap((person) =>
                person.externalIds ? person.externalIds.map((id) => id.tahoeId) : []
              )
            );
  
            [...prevData[category], ...newData[category]].forEach((item) => {
              if (item && item.externalIds && item.externalIds.length > 0) {
                item.externalIds.forEach((externalId) => {
                  if (externalId.tahoeId && !personsTahoeIds.has(externalId.tahoeId)) {
                    uniqueItems[externalId.tahoeId] = item;
                  }
                });
              }
            });
  
            updatedData[category] = Object.values(uniqueItems);
          } else {
            // Default deduplication by _id
            const uniqueItems = {};
            [...newData[category]].forEach((item) => {
              if (item && item._id) {
                uniqueItems[item._id] = item;
              }
            });
            updatedData[category] = Object.values(uniqueItems);
          }
        } else {
          updatedData[category] = newData[category];
        }
      });
  
      return updatedData;
    });
  }, []);
  

  return (
    <AllDataContext.Provider value={{ allData, setAllData, multiSelected, toggleObject, toggleSelectAll }}>
      {children}
    </AllDataContext.Provider>
  );
};
