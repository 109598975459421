// CaseContext.js
import { createContext, useReducer, useEffect } from "react";

export const CaseStateContext = createContext();
export const CaseDispatchContext = createContext();

export const caseReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CASE_NUMBER':
      return { caseNumber: action.payload };
    case 'CLEAR_CASE_NUMBER':
      return { caseNumber: null };
    default:
      return state;
  }
};

export const CaseContextProvider = ({ children }) => {
  const initialCaseNumber = localStorage.getItem('caseNumber') || null;

  const [state, dispatch] = useReducer(caseReducer, {
    caseNumber: initialCaseNumber,
  });

  useEffect(() => {
    const caseNumber = localStorage.getItem('caseNumber');

    if (caseNumber) {
      dispatch({ type: 'SET_CASE_NUMBER', payload: caseNumber });
    } else {
      dispatch({ type: 'CLEAR_CASE_NUMBER' });
    }
  }, []);

  // console.log('CaseContext state:', state);

  return (
    <CaseDispatchContext.Provider value={dispatch}>
      <CaseStateContext.Provider value={state}>
        {children}
      </CaseStateContext.Provider>
    </CaseDispatchContext.Provider>
  );
};