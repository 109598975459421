import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';
import { useLogout } from '../../hooks/useLogout';
import { max } from 'd3';

// Modal to allow user to request password reset link
// from their settings page.
const ResetPassModal = ({ onHide, ...props }) => {

    // attempt to get the user object from auth context.
    // If user accessed the modal from settings page, object is returned via authcontext
    // otherwise user is an empty object, if the object is empty, send an email to the backend route instead.
    const { user } = useAuthContext() || {};
    const [email, setEmail] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [toggleSuccessModal, setToggleSuccessModal] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [attempts, setAttempts] = useState(0);
    // Keep track of the number of requests made to the backend
    const [maxRequests, setMaxRequests] = useState(false);
    let postData;
    const navigate = useNavigate()
    const { logout } = useLogout()
    // logout user when successully requested password reset link
    const logoutHandler = () => {
        logout()
    }

    const handleClose = () => {
        onHide(); // Call onHide from parent component to close the modal (when reset password modal is called from settings page)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setEmailInvalid(false);
        // Clear existing timeout to prevent multiple resets
        if (timeoutId) clearTimeout(timeoutId)
        // Set a new timeout to reset attempts after 10 minutes
        const newTimeoutId = setTimeout(() => {
            setMaxRequests(false);
            setAttempts(0);
            setTimeoutId(null); // Clear the timeout ID after resetting
        }, 600000); // 600,000 milliseconds = 10 minutes

        setTimeoutId(newTimeoutId);
        // if user is not logged in, pass provided email to backend route
        if (!user) {
            postData = { userEmail: email };
        }
        // user is logged in, pass user email from object to backend route
        else {
            postData = { userEmail: user.email };
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/request-password-reset/`, postData);
            console.log(response.data.message);
            if (response.data.message === "Success") {
                setRequestSuccess(true);
                setLoading(false);
                setEmailInvalid(false);
                // logout user after sending them password reset link.
                logoutHandler();
                // close the reset password modal
                handleClose();
                alert("Successfully sent a link to reset your password!")
            }
        } catch (error) {
            setAttempts(prevAttempts => prevAttempts + 1);
            console.log("Error!", error);
            if (error.response.status === 429 || error.response.status === 403) {
                setAttempts(0);
                setMaxRequests(true);
            } else if (error.response.status === 404) {
                setMaxRequests(false);
                // user not found
                setEmailInvalid(true);
            }
            setLoading(false);
        }
        // console.log('Submitted email:', email);
    };

    // Cleanup timeout on component unmount to prevent memory leaks
    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]); // Depend on timeoutId so cleanup adjusts if it changes

    return (
        <>
            {/* Modal if reset link send success */}
            {requestSuccess ?
                <Modal
                    show={requestSuccess}
                    onHide={toggleSuccessModal}
                    centered
                    size='xl'
                    className='mt-3 text-left'
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Successfully Sent Password Reset Link.
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* window.location.reload() */}
                        We have successfully emailed you a link to reset your password. <br></br>
                        This link will expire in 6 hours.
                        <Button className='mt-3' style={{ 'width': '100%' }} onClick={() => navigate('/')}>Login</Button>
                    </Modal.Body>
                </Modal> : null
            }
            <Modal
                {...props}
                size="xl"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                onHide={handleClose}
                animation={true}
                className='mt-3 text-left'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change your password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {isLoading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='loadSpinner'
                                />
                                <span className="visually-hidden">Loading...</span>
                            </>
                        ) : null}
                        <Form.Group controlId="formBasicEmail">
                            {/* If we don't have a user object, allow client to provide an email. */}
                            {user &&                                     
                                <Form.Text className="text-muted mt-3">
                                    We will send a link to reset your password to your email address used for Lucidus Technologies.
                                </Form.Text>}
                            {!user &&
                                <>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className={emailInvalid ? 'is-invalid' : ''} // Manually adding 'is-invalid' class based on error state
                                        isInvalid={maxRequests}                      // Disable the form if max requests reached
                                    />
                                    <Form.Text className="text-muted mt-3">
                                        Please enter your email address associated with your Lucidus account.
                                        <br></br>
                                        We will send you an email with a link to reset your password.
                                    </Form.Text>
                                    {emailInvalid && (
                                        <Form.Control.Feedback className='mt-3' type="invalid">
                                            We could not find an account associated with that email address.<br></br>
                                        </Form.Control.Feedback>
                                    )}
                                    {maxRequests &&
                                        <Form.Control.Feedback type="invalid" className='mt-3' style={{ fontWeight: 'bold' }}>
                                            Maximum number of requests reached. Please try again later.
                                        </Form.Control.Feedback>
                                    }
                                    {attempts >= 1 && (
                                        <Form.Control.Feedback type='invalid' className="mt-3">
                                            Attempts Remaining: {Math.max(0, 4 - attempts)}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            }
                        </Form.Group>
                        {/* disable btn on maxRequests. */}
                        <Button style={{ width: '100%' }} className="mt-3" variant="primary" type="submit" disabled={maxRequests}>
                            Submit
                        </Button>

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ResetPassModal;
