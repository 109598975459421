import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "./final_adjusted_bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import '@fortawesome/fontawesome-free/css/all.min.css';


import { AuthContextProvider } from "./reducers/AuthContext";
import { OrgContextProvider } from "./reducers/OrgContext";
import { CaseContextProvider } from "./reducers/CaseContext";
import { AllDataProvider } from "./screens/newSearch/context/AllDataContext";
import { SelectedObjectProvider } from "./screens/newSearch/context/SelectedObjectContext";
import { MultiSelectProvider } from "./screens/newSearch/context/MultiSelect";
import { GlobalLoadingProvider } from "./screens/newSearch/context/GlobalLoadingContext";
import { ToastProvider } from "./screens/newSearch/context/ToastContext";
import { ResultsExpandedProvider } from "./screens/newSearch/context/ResultsExpanded";
import { GraphTypeProvider } from "./screens/newSearch/context/GraphTypeContext";
import { GraphSizeProvider } from "./screens/newSearch/context/GraphSizeContext";
import { AppProvider } from "./screens/newSearch/appSelector/Context/AppContext";
import { MapContextProvider } from "./screens/newSearch/context/MapContext";
import { GlobalDatesProvider } from "./screens/newSearch/context/GlobalDates";
import { HeatmapProvider } from "./screens/newSearch/context/HeatmapContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <OrgContextProvider>
      <CaseContextProvider>
        <AllDataProvider>
          <GlobalDatesProvider>
            <MapContextProvider>
              <SelectedObjectProvider>
                <MultiSelectProvider>
                  <HeatmapProvider>
                    <GlobalLoadingProvider>
                      <ToastProvider>
                        <ResultsExpandedProvider>
                          <GraphTypeProvider>
                            <GraphSizeProvider>
                              <AppProvider>
                                <App />
                              </AppProvider>
                            </GraphSizeProvider>
                          </GraphTypeProvider>
                        </ResultsExpandedProvider>
                      </ToastProvider>
                    </GlobalLoadingProvider>
                  </HeatmapProvider>
                </MultiSelectProvider>
              </SelectedObjectProvider>
            </MapContextProvider>
          </GlobalDatesProvider>
        </AllDataProvider>
      </CaseContextProvider>
    </OrgContextProvider>
  </AuthContextProvider>
);

reportWebVitals();
