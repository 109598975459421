import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(null);
  const [isRegistered, setRegistered] = useState(false); // Add this line
  const { dispatch } = useAuthContext();

  const signup = async (
    firstName,
    lastName,
    email,
    phone,
    agencyName,
    ori,
    isVeteran,
    password,
    referral
  ) => {
    setLoading(true);
    setError(null);
    setRegistered(false); // Reset the registration status at the start of signup

    const payload = {
        email,
        phone,
        firstName,
        lastName,
        agencyName,
        ori,
        isVeteran,
        password,
        referral
      };

    try {

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/signup`, payload);
        
        console.log("Response", response);
        const json = response.data;

      if (response.status === 200) {
        // save the user to local storage
        localStorage.setItem("user", JSON.stringify(json));

        // update the auth context
        dispatch({ type: "LOGIN", payload: json });
      } else if (response.status === 201) { // Check if the status is 201
        setRegistered(true); // Set isRegistered to true
      } else if (response.status === 400 && response.message === "Email Address In Use"){
        console.log("error: email address in use.");
        setError(error.response.data);
      }
       else {
        setError(json.error);
      }
    } catch (error) {
      setError(error.response.data);
    }

    setLoading(false);
  };

  return { signup, isLoading, error, isRegistered };
};