import { useAuthContext } from "./useAuthContext";
import { useCaseContext } from "./useCaseContext";
import { useEffect } from "react";

export const useLogout = () => {
    const { dispatch: authDispatch } = useAuthContext();
    const { dispatch: caseDispatch } = useCaseContext();

    const logout = () => {
        // remove user from storage
        localStorage.removeItem('user');

        // remove case from local storage
        localStorage.removeItem('caseNumber');

        // reset the modalShown flag in local storage
        localStorage.setItem('modalShown', 'false');

        // dispatch logout action
        authDispatch({ type: 'LOGOUT' });

        // dispatch clear case number action
        caseDispatch({ type: 'CLEAR_CASE_NUMBER' });
    };

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'user' && !e.newValue) {
                authDispatch({ type: 'LOGOUT' });
                caseDispatch({ type: 'CLEAR_CASE_NUMBER' });
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [authDispatch, caseDispatch]);

    return { logout };
};