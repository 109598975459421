import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';
import { useLogout } from '../hooks/useLogout';
import axios from 'axios';
import { Row } from 'react-bootstrap';

function NotificationsIndicator() {

    const { user } = useAuthContext()
    const decoded = jwtDecode(user.token)
    const userId = decoded._id
    const [ notificationCount, setNotificationCount ] = useState(0)
    const { logout } = useLogout()

    const countOfUnreadNotifications = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/notifications/${userId}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
              }
            })

            const unreadCount = response.data.reduce((count, notification) => {
              const unreadChanges = notification.changes.filter(change => !change.isRead).length;
              return count + unreadChanges;
            }, 0);

            setNotificationCount(unreadCount);
            // console.log('count', unreadCount)
        } catch (error) {
            console.error('Error:', error)
            if (error.response && error.response.data && error.response.data.type === 'EXPIRED_TOKEN') {
              logout()
            }
        }
    }

    useEffect(() => {
      let ws;
    
      const connect = () => {
        ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?userId=${userId}`);
    
        ws.onopen = () => {
          console.log('WebSocket connection established');
        };
    
        ws.onmessage = (event) => {
          const notification = JSON.parse(event.data);
          console.log('Received notification:', notification);
          countOfUnreadNotifications();
        };
    
        ws.onclose = (event) => {
          console.log('WebSocket connection closed', event.code, event.reason);
          console.log('Reconnecting...');
          setTimeout(connect, 5000); // try to reconnect after 5 seconds
        };
    
        ws.onerror = (error) => {
          console.log('WebSocket error:', error);
          ws.close();
        };
      };
    
      connect();
    
      countOfUnreadNotifications();
    
      return () => {
        ws.close();
      };
    }, []);

      return (
        <div style={{ marginTop: '10px', position: 'relative' }}>
          {notificationCount > 0 && 
            <>
            <span style={{
                position: 'absolute',
                top: '0',
                right: '50px',
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: '#4c9be8',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px'
              }}>
                {notificationCount > 99 ? '99+' : notificationCount}
              </span>
              <i className="fa-solid fa-bell me-4 fa-lg" style={{ color: '#4c9be8'}}></i>
            </>
          }
        </div>
      )
}

export default NotificationsIndicator