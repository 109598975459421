import React, { createContext, useContext, useState, useEffect } from 'react';

const GraphSizeContext = createContext();

export const useGraphSize = () => {
    return useContext(GraphSizeContext);
};

export const GraphSizeProvider = ({ children }) => {
    const [graphSize, setGraphSize] = useState(2);

    useEffect(() => {
        // console.log('graphSize updated in context:', graphSize);
    }, [graphSize]);

    return (
        <GraphSizeContext.Provider value={{ graphSize, setGraphSize }}>
            {children}
        </GraphSizeContext.Provider>
    );
};
