import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//Bootstrap
import { Navbar, Nav, Container, Row, Col, NavDropdown, Image, Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

//Hooks
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useOrgContext } from '../hooks/useOrgContext'; 

//import logo
import logoImageAPI from '../images/whiteAPItransparent.png'

//Components
// import OrgHandler from './orgHandler/OrgHandler';
import AuthenticatedOrgHandler from './orgHandler/AuthenticatedOrgHandler';

//jwt decode
import jwtDecode from 'jwt-decode';

//Custom CSS
import './dropdown.css'
import NotificationsIndicator from './NotificationsIndicator';

function APIHeader() {
    const navigate = useNavigate()
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const { organization } = useOrgContext()

    useEffect(() => {
        if (!user) {
            navigate("/login")
        } 
    }, [user, navigate])

    const token = user?.token
    const decoded = token ? jwtDecode(token) : null

    const initials = user && `${user.firstName[0]}${user.lastName[0]}`

    const logoutHandler = () => {
        logout()
    }

    
  return (
    <header>
        <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: '2', width: "100%" }}>
            <Container style={{ width: '100%'}}>
                <LinkContainer to="/api" className="ms-3"> 
                <Navbar.Brand>
                    <Image src={logoImageAPI} height="50px"/>
                </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div style={{ width: '100%'}}>
                        <Nav style={{ display: 'flex', flexDirection: 'row'}}>
                            <LinkContainer to="/api">
                                <Nav.Link className='ms-3'><i className="fas fa-home me-2"></i>Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/api/docs">
                                <Nav.Link className='ms-3'><i className="fas fa-book me-2"></i>Documentation</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/api/account">
                                <Nav.Link className='ms-3'><i className="fas fa-file-invoice me-2"></i>Account & Billing</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </div>
                    <div>
                        {user &&(
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}} className='user-container ms-3'>
                            <Nav>
                                <NavDropdown title={user.email} id="user-profile" className='me-3'>
                                    <LinkContainer to="/login">
                                        <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-right-from-bracket me-2"></i>Logout</NavDropdown.Item>   
                                    </LinkContainer>
                                </NavDropdown>
                            </Nav>
                            <div className="circle-icon">
                                {initials}
                            </div>
                        </div>
                        )}
                    </div>           
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
  )
}

export default APIHeader
