import React, { createContext, useContext } from 'react';
import { toast, ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

const customId = "custom-id";

export const ToastProvider = ({ children }) => {
    const notify = (message, options = {}) => {
        const id = toast(message, {
            ...options,
            toastId: customId,
            className: 'custom-toast',
            bodyClassName: 'custom-toast-body',
            progressClassName: 'custom-toast-progress',
        });
        return id;
    };

    const updateToast = (id, message, options = {}) => {
        toast.update(id, {
            ...options,
            render: message,
            className: 'custom-toast',
            bodyClassName: 'custom-toast-body',
            progressClassName: 'custom-toast-progress',
        });
    };

    return (
        <ToastContext.Provider value={{ notify, updateToast }}>
            {children}
            <ToastContainer 
                position="top-center"
                style={{ top: '90px' }}
                toastClassName="custom-toast"
            />
        </ToastContext.Provider>
    );
};
