import React, { createContext, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import ReactDOMServer from 'react-dom/server';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

const HTMLMessage = ({ html }) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const customId = "custom-id";

export const ToastProvider = ({ children }) => {
    const notify = (message, options = {}) => {
        const { toastType } = options;

        const className = toastType === 'signalsError' ? 'error-toast' : 'custom-toast';
        const bodyClassName = toastType === 'signalsError' ? 'error-toast-body' : 'custom-toast-body';
        const progressClassName = toastType === 'signalsError' ? 'error-toast-progress' : 'custom-toast-progress';

        const renderedMessage =
            toastType === 'signalsError' ? (
                <HTMLMessage html={message} />
            ) : (
                message
            );

        const id = toast(renderedMessage, {
            ...options,
            toastId: customId,
            className,
            bodyClassName,
            progressClassName,
        });
        return id;
    };

    const updateToast = (id, message, options = {}) => {
        const { toastType } = options;

        const className = toastType === 'signalsError' ? 'error-toast' : 'custom-toast';
        const bodyClassName = toastType === 'signalsError' ? 'error-toast-body' : 'custom-toast-body';
        const progressClassName = toastType === 'signalsError' ? 'error-toast-progress' : 'custom-toast-progress';

        const renderedMessage =
            toastType === 'signalsError' ? (
                <HTMLMessage html={message} />
            ) : (
                message
            );

        toast.update(id, {
            ...options,
            render: renderedMessage,
            className,
            bodyClassName,
            progressClassName,
        });
    };

    return (
        <ToastContext.Provider value={{ notify, updateToast }}>
            {children}
            <ToastContainer 
                position="top-center"
                style={{ top: '90px' }}
                toastClassName="custom-toast"
            />
        </ToastContext.Provider>
    );
};
