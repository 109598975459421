import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button, Alert, Modal, Image } from 'react-bootstrap';
import { useLogin } from '../../hooks/useLogin';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ResetPassModal from '../settings/ResetPassModal';
import logoImageHealth from '../../images/HCSWHITE.png';
import lucidusLogo from '../../images/LucidusWhiteOnTransparent.png'

function LoginScreen({ location, history }) {

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ twoFactorCode, setTwoFactorCode ] = useState('');
  const [ trustThisBrowser, setTrustThisBrowser ] = useState(false);
  const [ showTwoFactorModal, setShowTwoFactorModal ] = useState(false);
  const [ invalidCredentials, setInvalidCredentials ] = useState(false);
  const [ accountDisabled, setAccountDisabled ] = useState(false);
  const [ accountUnverified, setAccountUnverified ] = useState(false);
  const [ lastAttempt, setLastAttempt ] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);  
  const [loginError, setLoginError] = useState(false); 
  const { dispatch } = useAuthContext()
  const { login, error, isLoading } = useLogin();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(error);
    if (error === "Invalid Login Credentials") {
      setInvalidCredentials(true);
    } else if (error === "Account is disabled") {
      setAccountDisabled(true);
    } else if (error === "Account Not Verified") {
      setAccountUnverified(true);
    } else if (error === "Last Attempt") {
      setLastAttempt(true)
    } else if (error === "Request failed with status code 429" || error === "Request failed with status code 403") {
      // Too many requests in a 10 minute window
      setLoginError(true);
    }
  }, [error]);


  const handleLoginSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const { requiresTwoFactorAuth, data } = await login(email, password, trustThisBrowser);
  
      console.log('requiresTwoFactorAuth:', requiresTwoFactorAuth);
      console.log('data:', data);
  
      if (requiresTwoFactorAuth) {
        setShowTwoFactorModal(true);
      } else if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch({ type: "LOGIN", payload: data });

        //Pendo
        window.pendo.identify({
          visitor: {
            id: data.email,
            name: data.firstName + ' ' + data.lastName,
          },
          account: {
            id: data.email,
          }
        })

        navigate('/home');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const { data } = await login(email, password, trustThisBrowser, twoFactorCode);
      
      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch({ type: "LOGIN", payload: data });

        window.pendo.identify({
          visitor: {
            id: data.data.email,
            name: data.data.firstName + ' ' + data.data.lastName,
          }
        })
        navigate('/home');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const LoginErrorModal = () => { 
  
    return(
      <Modal size='lg' centered show={loginError}>
        <Modal.Header>
          Too Many Failed Login Attempts.
          <br></br>
          Please Try Again Later.
        </Modal.Header>
      </Modal>
    )
  }

  const isHealthcareSafely = window.location.href.startsWith('https://app.healthcaresafely.com');
  const isLucidusTech = window.location.href.startsWith('https://app.lucidustech.com')  || window.location.href.startsWith('http://localhost:3000');


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loginError && <LoginErrorModal />}
        <Modal
          size='lg'
          centered
          show={showTwoFactorModal}
          onHide={() => setShowTwoFactorModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Two Factor Authentication Required
            </Modal.Title>
            <Modal.Body>
              You Will Receive an Email with Your Two-Factor Authentication Code
              <Form onSubmit={handleTwoFactorSubmit}>
                <Form.Group>
                  <Form.Label>Two Factor Code</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Type or Paste Your Two Factor Code"
                    value={twoFactorCode}
                    onChange={(e) => setTwoFactorCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check 
                    label="Check This Box to Trust This Browser for 30 Days"
                    checked={trustThisBrowser}
                    onChange={(e) => setTrustThisBrowser(e.target.checked)}
                  />
                </Form.Group>
                <Button type='submit' variant='primary' style={{ width: '100%' }} className='mt-3' disabled={isLoading}>
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal.Header>
        </Modal>
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <Row>
            <Col>
              <Form
                onSubmit={handleLoginSubmit}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
              >
                {isHealthcareSafely && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Image src={logoImageHealth} height='140px' width='180px' />
                    <Row className='mt-3 mb-3'>
                      <h1 style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className='my-3'>
                        Login to HealthCare Safely
                      </h1>
                    </Row>
                  </div>
                )}
                {isLucidusTech && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Image src={lucidusLogo} height='140px' width='350px' />
                  <Row className='mt-3 mb-3'>
                    <h1 style={{ display: 'flex', justifyContent: 'center', width: '100%', }} className='my-3'>
                      Login to Lucidus LE
                    </h1>
                  </Row>
                </div>
                )}
                <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email'
                    size='lg'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} className='my-1 mb-3' sm={12} md={6} lg={3} style={{ width: '100%' }}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password'
                    size='lg'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type='submit' variant='primary' style={{ width: '100%' }} className='mt-3' disabled={isLoading}>
                  Login
                </Button>
                <Button
                  variant="link"
                  style={{ marginTop: '10px' }}
                  onClick={() => setShowForgotPasswordModal(true)}
                >
                  Forgot Password?
                </Button>
              </Form>
              <ResetPassModal
                show={showForgotPasswordModal}
                onHide={() => setShowForgotPasswordModal(false)}
              />
              {invalidCredentials && (
                <Row className='my-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col>
                    <Alert variant='danger'>
                      <h6 style={{ textAlign: 'center' }}>Invalid Login Credentials</h6>
                    </Alert>
                  </Col>
                </Row>
              )}
              {accountUnverified && (
                <Row className='my-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col>
                    <Alert variant='danger'>
                      <h6 style={{ textAlign: 'center' }}>Your Account Is Not Verified, <br></br>Please Contact system@lucidustech.com</h6>
                    </Alert>
                  </Col>
                </Row>
              )}
              {accountDisabled && (
                <Row className='my-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col>
                    <Alert variant='danger'>
                      <h6 style={{ textAlign: 'center' }}>Your Account Has Been Disabled, <br></br>Please Contact system@lucidustech.com</h6>
                    </Alert>
                  </Col>
                </Row>
              )}
              {lastAttempt && (
                <Row className='my-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col>
                    <Alert variant='danger'>
                      <h6 style={{ textAlign: 'center' }}>You have 1 Attempt Remaining <br></br> Before Your Account is Disabled</h6>
                    </Alert>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default LoginScreen
