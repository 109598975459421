import React, { createContext, useContext, useState, useEffect } from 'react';

const WhatsHereContext = createContext();

export const useWhatsHere = () => {
    return useContext(WhatsHereContext);
}

export const WhatsHereProvider = ({ children }) => {
    const [whatsHere, setWhatsHere] = useState(null);
    const [selectedWhatsHere, setSelectedWhatsHere] = useState([]);

    useEffect(() => {
        console.log('whatsHere updated in context:', whatsHere);
        console.log('selectedWhatsHere updated in context:', selectedWhatsHere);
    }, [whatsHere, selectedWhatsHere]);

    return (
        <WhatsHereContext.Provider value={{ whatsHere, setWhatsHere, selectedWhatsHere, setSelectedWhatsHere }}>
            {children}
        </WhatsHereContext.Provider>
    );
}
